.showbg .newscard {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #0a1f3574 100%);
    width: 100%;
    background-size: cover;
    /* background-color: #0a1f355d; */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    box-shadow: 0px 0px 250px #0a1f35 inset;
}