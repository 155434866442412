/* .showcase {
    width: 100%;
    height: 500px;
    position: relative;
    color: white;
    text-align: center;
} */

/* .showcase img {
    width: 100%;
    height: 100%;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20
} */

.showcase .overlay {
    width: 100%;
    /* height: 500px; */
    background-size: cover;
    background-color: #0a1f355d;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    box-shadow: 400px 10px 300px #0a1f35cc inset;

}